import React from "react";

const Social = () => {
  return (
    <div className="home__social">
      <a href="instagram.com" className="home__social-icon" target={"_blank"}>
        <i class="bx bxl-instagram"></i>
      </a>

      <a href="facebook.com" className="home__social-icon" target={"_blank"}>
        <i class="bx bxl-facebook-circle"></i>
      </a>

      <a href="linkedin.com" className="home__social-icon" target={"_blank"}>
        <i class="bx bxl-linkedin"></i>
      </a>
    </div>
  );
};

export default Social;
